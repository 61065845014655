document.addEventListener('DOMContentLoaded', () => {
  const nonce = $('[name=csp-nonce]').attr('content');

  // slick-carousel binding and configuration
  $('.slick').slick({
    autoplay: false,
    mobileFirst: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: arrowTemplate('prev'),
    nextArrow: arrowTemplate('next'),
    infinite: false,
    nonce: nonce,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 975,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 319,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  } as any);

  $('.slick-title').slick({
    autoplay: false,
    mobileFirst: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: false,
    prevArrow: arrowTemplate('prev'),
    nextArrow: arrowTemplate('next'),
    nonce: nonce,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 975,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 319,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  } as any);
});

function arrowTemplate (text: string) {
  return `
    <button class="slick-${text}" type="button">
    <span class="slick-${text}-icon" aria-hidden="true"></span>
  `;
}
