import posthog from 'posthog-js';

(function () {
  if (window.POSTHOG_API_KEY === '' || window.POSTHOG_HOST === '') {
    return;
  }

  // Init posthog and automatically track pageviews
  posthog.init(window.POSTHOG_API_KEY, {
    api_host: window.POSTHOG_HOST,
    autocapture: true,
    capture_pageview: true,
    capture_pageleave: true,
  });
})();
