/*
private props:
  id (unique)           --> unique identifier for the preloader element in the DOM
  fullscreen (boolean)  --> whether the preloader should take the whole screen
  target (DOMElement)   --> if the preloader is not fullscreen, this is the container element of the preloader

public instance methods:
  fire(): creates the preloader element
  remove(): removes the preloader from the DOM, given an ID

private instance methods:
  fire_fullscreen(): handles the logic to create a fullscreen preloader
  fire_local(): handles the logic to create and embed the preloader inside a target element
  build_template(): returns a string containing the HTML template of the preloader
*/

export default class Preloader {
  #id: string;
  #fullscreen: boolean;
  #target: HTMLElement;

  constructor(options: { fullscreen: boolean, target: HTMLElement } = { fullscreen: false, target: null }) {
    this.#fullscreen = options.fullscreen;
    this.#target = options.target;

    this.#id = Math.random().toString(16).slice(2);
  }

  fire() {
    this.#fullscreen ? this.#fire_fullscreen() : this.#fire_local(this.#target);
  }

  remove() {
    const preloader = document.querySelector(`#js-preloader-${this.#id}`);
    if (!preloader) { return; }

    preloader.remove();
  }

  #fire_fullscreen() {
    const template = this.#build_template();

    const html_template = document.createElement('template');
    html_template.innerHTML = template;

    const documentBody = document.querySelector('body');
    documentBody.appendChild(html_template.content.firstChild);
  }

  #fire_local(target: HTMLElement = null) {
    const template = this.#build_template('modal-backdrop--adjust modal-backdrop--height');

    target.innerHTML = template;
  }

  #build_template(wrapper_css_class = '') {
    return `
      <div id=js-preloader-${this.#id} class="modal-backdrop show ${wrapper_css_class}">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="ml-2 spinner-border text-primary" role="status"></div>
        </div>
      </div>
    `.trim();
  }
}
