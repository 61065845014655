// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// starting and binding the Rails object explicitly to jQuery
// https://stackoverflow.com/a/57543894
import * as Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import '@rails/actioncable';
import 'popper.js/dist/popper';
import 'bootstrap';
import 'bootstrap-select/js/bootstrap-select';
import 'slick-carousel/slick/slick';
import './components/slick';
import './components/remote_links';
import './sentry';
import './posthog';
import 'channels';

Rails.start();
ActiveStorage.start();

// jQuery globals
$.rails = Rails;
window.romulus = {};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('DOMContentLoaded', () => {
  // Bind on button to close window
  $(document).on('click', '.close-window-button', function() {
    window.close();
  });

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toogle="popover"]').popover();

  // Search
  // TODO: this code will require to manage advanced_search eventually (when advanced search for other blocks will be present)
  // TODO: Will require to change both select and form actions simultaneously.
  // TODO: Syncronize 'q' field and 'title' field or other basic term search field of block (later)
  $(document).on('change', '.search-type', function() {
    const $selected = $(this).find(':selected');
    const $search_role = $(this.form).find('.search-role');

    ($selected.val() === 'authors') ? $search_role.val('author') : $search_role.val('');

    $(this.form).attr('action', $selected.data('action'));
  });

  $(document).on('click', '.reset', function(event) {
    event.stopPropagation();
    $('#advanced_search input').each(function() { $(this).val(''); });
    $('#advanced_search select').each(function() { $(this).val('all'); });
  });
});
