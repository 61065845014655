import Preloader from './preloader';

$(document).on('DOMContentLoaded', function() {
  const preloader = new Preloader({fullscreen: true, target: null});

  $('[data-remote=true]:not(.page-link)').on('ajax:send', function() {
    preloader.fire();
  });

  $('[data-remote=true]:not(.page-link)').on('ajax:complete', function() {
    preloader.remove();
  });

  // the ajax:complete event is not fired if the element is removed from the DOM
  // in that case, we need to be aware of this and remove the preloader once it is gone
  preloaderRemovalObserver(
    document.querySelectorAll('[data-remote=true]:not(.page-link)'),
    preloader
  );
});

function preloaderRemovalObserver(ajaxElements: NodeListOf<HTMLElement>, preloader: Preloader) {
  // no need to set the observer if there are no elements to keep an eye on
  if (ajaxElements === null) { return; }

  const observer = new MutationObserver((records) => {
    for (const record of records) {
      // check if the removed node contains one of the ajax elements
      record.removedNodes.forEach((node) => {
        ajaxElements.forEach((element) => {
          if (node.contains(element)) { preloader.remove(); }
        });
      });
    }
  });

  observer.observe(
    document.querySelector('body'),
    {childList: true, subtree: true}
  );
}
